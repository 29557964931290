export const SelectLists = {
  'Démarches pour moyens auxilaires': ['AVS', 'AI', 'CM'],
  'Température des pieds': ['Chauds', 'Froids'],
  Ongles: ['Longs', 'Courts', 'Incarnés', 'En rond', 'Épais', 'Mycoses'],
  'État de la peau': ['Sèche', 'Humide'],
  Chaussures: ['Larges', 'Étroites', 'Ouvertes', 'Fermées', 'En cuir', 'Synthétiques']
}

export class LabelsHelper {
  private static readonly defaultLocalisationItem = {
    tagId: 1,
    tag: 'Localisation',
    value: '',
    type: 'text'
  }

  private static readonly defaultRemarkItem = {
    tagId: 1,
    tag: 'Remarque',
    value: '',
    type: 'text'
  }

  public static GetTags () {
    return [
      {
        title: 'Situation',
        index: 1,
        evaluationPodoLinkedItems: [
          {
            tag: 'Déjà eu une plaie',
            value: false,
            visible: false,
            tagId: 1,
            linkedItems: [
              {
                tagId: 1,
                tag: 'Durée',
                value: '',
                type: 'text'
              },
              {
                tagId: 2,
                tag: 'Année',
                value: '',
                type: 'number'
              }
            ]
          },
          {
            tag: 'Démarches pour moyens auxilaires',
            value: [],
            visible: false,
            tagId: 2,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Décision',
            value: false,
            visible: false,
            tagId: 3,
            linkedItems: [
              {
                tagId: 1,
                tag: 'Décision',
                value: '',
                visible: false,
                type: 'text'
              }
            ]
          }
        ]
      },
      {
        title: 'Évaluation générale',
        index: 2,
        evaluationPodoLinkedItems: [
          {
            tag: 'Lit plantaire',
            value: false,
            visible: false,
            tagId: 1,
            linkedItems: [
              {
                tagId: 1,
                tag: 'Reception',
                value: '',
                visible: false,
                type: 'date'
              },
              {
                tagId: 2,
                tag: 'Dernier contrôle',
                value: '',
                visible: false,
                type: 'date'
              }
            ]
          },
          {
            tag: 'Température des pieds',
            value: [],
            visible: false,
            tagId: 2,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Orteils en marteau',
            value: false,
            visible: false,
            tagId: 3,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Ongles',
            value: [],
            visible: false,
            tagId: 4,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          }
        ]
      },
      {
        title: 'Évaluation de la peau',
        index: 3,
        evaluationPodoLinkedItems: [
          {
            tag: 'État de la peau',
            value: [],
            visible: false,
            tagId: 1,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Rougeurs',
            value: false,
            visible: false,
            tagId: 2,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Oedèmes',
            value: false,
            visible: false,
            tagId: 3,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Cyanosés',
            value: false,
            visible: false,
            tagId: 4,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Crevasses',
            value: false,
            visible: false,
            tagId: 5,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Blessures, plaies, cloques',
            value: false,
            visible: false,
            tagId: 6,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Macération interdigitale',
            value: false,
            visible: false,
            tagId: 7,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Mycoses',
            value: false,
            visible: false,
            tagId: 8,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Zones hyperkératose',
            value: false,
            visible: false,
            tagId: 9,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Cor, oeil-de-perdrix, verrue',
            value: false,
            visible: false,
            tagId: 10,
            linkedItems: [
              { ...this.defaultLocalisationItem }
            ]
          },
          {
            tag: 'Déformations (pieds plats, hallux valgus, etc)',
            value: false,
            visible: false,
            tagId: 11,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          }
        ]
      },
      {
        title: 'Matériel et médicaments',
        index: 4,
        evaluationPodoLinkedItems: [
          {
            tag: 'Chaussures orthopédiques',
            value: false,
            visible: false,
            tagId: 1,
            linkedItems: [
              {
                tagId: 1,
                tag: 'Depuis quand',
                value: '',
                type: 'date'
              }
            ]
          },
          {
            tag: 'Chaussures',
            value: [],
            visible: false,
            tagId: 2,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Chaussures avec coutures intérieures',
            value: false,
            visible: false,
            tagId: 3,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          },
          {
            tag: 'Anticoagulation',
            value: false,
            visible: false,
            tagId: 4,
            linkedItems: [
              { ...this.defaultRemarkItem }
            ]
          }
        ]
      }
    ]
  }
}
