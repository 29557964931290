import { Component, Vue, Watch } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import SensibilityMeasure from '@/components/SensibilityComponent/SensibilityMeasure.vue'
import { ErrorService } from '@/services/error.service'
import { ValidationObserver } from 'vee-validate'
import { LinkedItemStatic, PodoEvaluationModel, PodoSection, PodoSub, SensibilityMeasureModel } from '@/models/podo-evaluation-model'
import { PodoService } from '@/services/patient-podo-service'
import { AxiosResponse } from 'axios'
import { LabelsHelper, SelectLists } from './LabelsHelper'
import Commons from '@/components/shared/Helpers/commons'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver,
    SensibilityMeasure
  }
})
export default class EvaluationMesure extends Vue {
  private podoService = PodoService.getInstance()
  public isSaving = false
  public errorMessages: string[] = []
  public dossierId!: string
  public podoId = ''
  public sections: PodoSection[] = []
  public selectItems = SelectLists
  public evaluationPodo: PodoEvaluationModel = {
    evaluationDate: Commons.GetTodayFormatted(),
    evaluationPodoLinkedItems: []
  }

  public measureL: SensibilityMeasureModel = { piedType: 1 }
  public measureR: SensibilityMeasureModel = { piedType: 2 }

  public showConfirm = false
  public routeNextCallback = null as any
  public redirectPath = null as string | null
  public hasSaved = false

  beforeRouteLeave (to, from, next) {
    if (!this.hasSaved) {
      if (this.redirectPath == null) {
        this.redirectPath = to
      }
      this.showConfirm = true
      this.routeNextCallback = next
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.routeNextCallback) {
      this.routeNextCallback()
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public async mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    Commons.updateDossierName()
    this.sections = LabelsHelper.GetTags()
    this.podoId = this.$route.params.id
    this.dossierId = this.$route.params.dossierId
    this.getNextDate()
    if (this.podoId !== 'new') {
      const evaluation = await this.podoService.GetDossierPodoEvaluation(this.podoId)
      this.setupEvaluationObjects(evaluation)
    } else {
      const evaluation = await this.podoService.GetLatestPodoEvaluation(this.dossierId)
      if (evaluation) {
        evaluation.id = undefined
        if (evaluation.sensibiliteMeasure) {
          evaluation.sensibiliteMeasure.forEach(s => {
            s.id = undefined
            s.podoId = undefined
          })
        }
        if (evaluation.evaluationPodoLinkedItems) {
          evaluation.evaluationPodoLinkedItems.forEach(p => {
            p.id = undefined
            p.podoItemId = undefined
          })
        }
        evaluation.evaluationDate = Commons.GetTodayFormatted()
        this.setupEvaluationObjects(evaluation)
        this.getNextDate()
      }
    }
  }

  private setupEvaluationObjects (evaluation: PodoEvaluationModel) {
    this.measureL = evaluation.sensibiliteMeasure?.find(x => x.piedType === 1) ?? this.measureL
    this.measureR = evaluation.sensibiliteMeasure?.find(x => x.piedType === 2) ?? this.measureR
    this.evaluationPodo = evaluation
    this.mapToSectionItems()
  }

  private mapToSectionItems () {
    const sectionMain = this.evaluationPodo.evaluationPodoLinkedItems
    sectionMain.forEach(linkedItem => {
      const tagIds = linkedItem.tagId.split(':')
      const idx = tagIds[0]
      const staticSection = this.sections.find(s => s.index === +idx) as PodoSection
      const staticSectionPodoItem = staticSection.evaluationPodoLinkedItems.find(
        p => `${staticSection.index}${p.tagId}` === `${tagIds[0]}${tagIds[1]}`) as PodoSub

      if (tagIds.length === 2 && linkedItem.value) {
        const val = ['true', 'false'].includes(linkedItem.value) ? !!linkedItem.value : linkedItem.value.split(':')
        staticSectionPodoItem.visible = !!val
        staticSectionPodoItem.value = val
      }

      if (tagIds.length === 3) {
        const idx2 = `${tagIds[0]}${tagIds[1]}${tagIds[2]}`
        const staticLinkedItem = staticSectionPodoItem.linkedItems.find(
          i => `${staticSection.index}${staticSectionPodoItem.tagId}${i.tagId}` === idx2) as LinkedItemStatic
        staticLinkedItem.value = linkedItem.value
      }
    })
  }

  @Watch('evaluationPodo.evaluationDate')
  public getNextDate () {
    const date = new Date(this.evaluationPodo.evaluationDate)
    const d = date.getDate()
    date.setUTCMonth(date.getMonth() + 6)
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    date.setUTCDate(date.getUTCDate() + 1)
    this.evaluationPodo.nextEvaluationDate = date.toISOString().substr(0, 10)
  }

  public inputChange (value: any, indexes: string, section: PodoSub) {
    const idxes = indexes.split('-')
    const idx = `${+idxes[0] + 1}:${+idxes[1] + 1}`
    const sectionItems = this.evaluationPodo.evaluationPodoLinkedItems
    const sectionMain = sectionItems.find(x => x.tagId === idx)
    const val = Array.isArray(value) ? (value as string[]).join(':') : value
    if (section) section.visible = !!val
    if (!sectionMain) {
      sectionItems.push({ tagId: idx, value: val })
    }

    if (idxes.length === 2 && sectionMain) {
      sectionMain.value = val
    }

    if (idxes.length === 3) {
      const idx = `${+idxes[0] + 1}:${+idxes[1] + 1}:${+idxes[2] + 1}`
      const linkedItem = sectionItems.find(x => x.tagId === idx)

      if (linkedItem) {
        linkedItem.value = value
      } else {
        sectionItems.push({ tagId: idx, value: value })
      }
    }
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      this.evaluationPodo.sensibiliteMeasure = [{ ...this.measureL, dossierId: this.dossierId }, { ...this.measureR, dossierId: this.dossierId }]
      const res = await this.podoService.AddPodoEvaluation(this.dossierId, this.evaluationPodo)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })

      if ((res as AxiosResponse<PodoEvaluationModel>)?.status === 200) {
        this.hasSaved = true
        this.redirectPath = null
        this.returnToList()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public buttonBack () {
    // act as if we saved, so that we bypass the confirm dialog
    this.hasSaved = true
    this.returnToList()
  }

  public returnToList (forceReset = false) {
    if (forceReset) {
      this.redirectPath = null
    }
    if (this.redirectPath == null) {
      this.$router.go(-1)
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
