import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SensibilityMeasure extends Vue {
  @Prop()
  public orientation!: number

  @Prop()
  public title!: string

  @Prop()
  public measure!: SensibilityMeasure
}
