import { dossierPatientModel } from '@/models/dossier-response-model'
import { PodiatryObservationModel } from '@/models/podiatry-observation-model'
import { PodoEvaluationModel } from '@/models/podo-evaluation-model'
import { BehaviorSubject } from 'rxjs'
import http from '@/http-client'

export class PodoService {
  private static instance: PodoService
  private _podoObservationSelectedSource = new BehaviorSubject<any>({})
  public podoObservationSelected$ = this._podoObservationSelectedSource.asObservable()

  private _podoObservationsSource = new BehaviorSubject<PodiatryObservationModel[]>([]) // is this needed ?
  public podoObservations$ = this._podoObservationsSource.asObservable()

  public activeIndex = 0

  public static getInstance (): PodoService {
    if (!PodoService.instance) {
      PodoService.instance = new PodoService()
    }
    return PodoService.instance
  }

  public setTabIndex (idx: number) {
    this.activeIndex = idx
  }

  public getTabIndex () {
    return this.activeIndex
  }

  public updatePodoObservationSelected (observation: PodiatryObservationModel) {
    this._podoObservationSelectedSource.next(observation)
  }

  public async GetDossierPodoObservations (dossierId: string) {
    const response = await http.get(`/dossierPatient/podiatry/observations/${dossierId}`)
    this._podoObservationsSource.next(response.data)
    return (response.data)
  }

  public async AddPodoObservation (dossierId: string, observationItem: PodiatryObservationModel) {
    observationItem.patientId = dossierId
    return await http.post(`/dossierPatient/podiatry/observation/${dossierId}`, observationItem)
  }

  public async DeletePodoObservation (observationId: string) {
    return await http.delete(`/dossierPatient/podiatry/observation/${observationId}`)
  }

  // Evaluation
  public async AddPodoEvaluation (dossierId: string, evaluationItem: PodoEvaluationModel) {
    evaluationItem.dossierId = dossierId
    return await http.post(`/dossierPatient/podiatry/evaluation/${dossierId}`, evaluationItem)
  }

  public async GetDossierPodoEvaluation (podoId: string) {
    const response = await http.get(`/dossierPatient/podiatry/evaluation/${podoId}`)
    return (response.data) as PodoEvaluationModel
  }

  public async GetLatestPodoEvaluation (dossierId: string) {
    const response = await http.get(`/dossierPatient/podiatry/evaluations/${dossierId}/latest`)
    return (response.data) as PodoEvaluationModel
  }

  public async GetDossierPodoEvaluations (dossierId: string) {
    const response = await http.get(`/dossierPatient/podiatry/evaluations/${dossierId}`)
    return (response.data) as PodoEvaluationModel[]
  }

  public async deleteEvaluation (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/podiatry/evaluation/${id}`)
  }
}
